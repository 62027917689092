.App {
  text-align: center;
  margin: 50px;
}

img {
  margin: 50px;
  height: 200px;
}

.fa {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-instagram {
  background: #ff0000;
  color: white;
}

.fa-github {
  background: #000000;
  color: white;
}




@media screen and (max-width: 480px) {
  .portfolio-div > a > img {
    margin: 50px 0px;
    height: 100px;
  }
  .App {
    text-align: center;
    margin: 0px;
  }
}
